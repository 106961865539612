<template>
    <v-card v-bind="$attrs" v-on="$listeners">
        <v-card-title
            class="subtitle-1 font-weight-medium text--secondary pt-6"
        >
            {{ title }}
        </v-card-title>
        <v-container fluid v-if="grid">
            <v-row class="px-4" align="center">
                <v-col
                    v-for="(item, index) in fields"
                    :key="index"
                    :cols="item.cols || 12"
                    :sm="item.sm || 6"
                    :md="item.md || 3"
                    :class="item.colClass"
                >
                    <slot name="content" :item="item" :index="index"></slot>
                </v-col>
                <slot name="checkbox"></slot>
            </v-row>

            <v-row class="px-4" align="center" v-if="costo">
                <v-col
                    v-for="(item, index) in costos"
                    :key="index"
                    :cols="item.cols || 12"
                    :sm="item.sm || 6"
                    :md="item.md || 3"
                    :class="item.colClass"
                >
                    <slot
                        name="costoContent"
                        :item="item"
                        :index="index"
                    ></slot>
                </v-col>
                <slot name="table"></slot>
            </v-row>
        </v-container>
        <slot name="updateContent"></slot>
        <v-card-actions class="d-flex justify-end">
            <Button
                @click="$emit('cancel')"
                text="Cancelar"
                color="gray darken-1"
            />
            <Button
                @click="$emit('accept')"
                :text="acceptText"
                color="primary"
                dark
            />
        </v-card-actions>
    </v-card>
</template>

<script>
import Button from "@/components/vuetify/Button";

export default {
    components: {
        Button,
    },
    props: {
        title: {
            type: String,
            default: "Editar cliente mayorista",
        },
        grid: {
            type: Boolean,
            default: true,
        },
        costo: {
            type: Boolean,
            default: false,
        },
        fields: {
            type: Array,
            default: () => [],
            required: false,
        },
        costos: {
            type: Array,
            default: () => [],
            required: false,
        },
        acceptText: {
            type: String,
            default: "Actualizar",
        },
    },
};
</script>
