<template>
    <div :class="$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'">
        <DataTable
            tableClass="elevation-1"
            :headers="headers"
            :items="customers"
        >
            <template #top>
                <v-container fluid>
                    <v-row>
                        <v-col class="d-flex"
                            ><v-toolbar-title>{{
                                $vuetify.breakpoint.smAndUp
                                    ? "Lista de usuarios mayoristas"
                                    : "Lista Mayorista"
                            }}</v-toolbar-title>
                            <v-divider vertical class="ml-5"></v-divider
                        ></v-col>
                        <v-spacer></v-spacer>
                        <v-col class="d-flex justify-end">
                            <Dialog
                                v-model="createDialog.showDialog"
                                width="800"
                            >
                                <template #activator="{ on, attrs }">
                                    <Button
                                        text="Nuevo"
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>
                                <Card
                                    :fields="fields"
                                    @cancel="cancelDialog(createDialog)"
                                    acceptText="Crear"
                                    @accept="postCostumer"
                                    title="Crear cliente mayorista"
                                >
                                    <template #content="{ item }">
                                        <TextField
                                            v-if="
                                                !item.isProvince &&
                                                !item.isLocation
                                            "
                                            v-model.number="item.model"
                                            :label="item.label"
                                            :type="item.type"
                                        />
                                        <Select
                                            v-if="item.isProvince"
                                            v-model="storeProvince"
                                            label="Provincia"
                                            :items="storeProvinces"
                                            item-text="provincia"
                                        />
                                        <Select
                                            v-if="item.isLocation"
                                            v-model="storeLocation"
                                            label="Localidad"
                                            :items="storeLocations"
                                            item-text="localidad"
                                        />
                                    </template>
                                    <template #checkbox>
                                        <v-checkbox
                                            class="pl-2"
                                            v-model="checkbox"
                                            label="Agregar direccion de envio distinta al local"
                                        ></v-checkbox>
                                        <v-container v-if="checkbox">
                                            <v-row>
                                                <v-col cols="12" sm="6" md="3">
                                                    <Select
                                                        label="Provincia"
                                                        v-model="province"
                                                        :items="provinces"
                                                        item-text="provincia"
                                                    />
                                                </v-col>
                                                <v-col cols="12" sm="6" md="3">
                                                    <Select
                                                        label="Localidad"
                                                        v-model="location"
                                                        :items="locations"
                                                        item-text="localidad"
                                                    />
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <TextField
                                                        label="Direccion"
                                                        v-model="address"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </template>
                                </Card>
                            </Dialog>
                        </v-col>
                    </v-row>
                </v-container>
                <div v-for="(snackbar, index) in snackbars" :key="index">
                    <Snackbar
                        v-model="snackbar.display"
                        :color="snackbar.color"
                        top
                        right
                        class="pt-15"
                    >
                        <span class="color-negro-1 f-body-2 mulish">
                            {{ snackbar.text }}
                        </span>
                        <template v-slot:action="{ attrs }">
                            <Icon
                                v-bind="attrs"
                                color="black"
                                icon="mdi-close"
                                @click="snackbar.display = false"
                            />
                        </template>
                    </Snackbar>
                </div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
                {{ transformDate(item.created_at, "LL") }}
            </template>
            <template v-slot:[`item.nombre`]="{ item }">
                {{ item.nombre + " " + item.apellido }}
            </template>
            <template v-slot:[`item.direccion`]="{ item }">
                {{
                    item.localidad.provincia.provincia +
                    ", " +
                    item.localidad.localidad +
                    ", " +
                    item.direccion
                }} </template
            ><template v-slot:[`item.envio`]="{ item }">
                {{
                    item.localidad_entrega.provincia.provincia +
                    ", " +
                    item.localidad_entrega.localidad +
                    ", " +
                    item.direccion_entrega
                }}
            </template>
            <template v-slot:[`item.id`]="{ item }">
                <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                        <Icon
                            class="mr-2 white-color"
                            icon="mdi-dots-vertical"
                            v-bind="attrs"
                            v-on="on"
                            small
                        ></Icon>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(el, i) in items"
                            :key="i"
                            @click="
                                i === 0
                                    ? (item.dialog = true)
                                    : resendEmail(item)
                            "
                        >
                            <v-list-item-title>{{
                                el.title
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <!-- <Icon small icon="mdi-pencil" @click="item.dialog = true" /> -->
                <Dialog v-model="item.dialog" grid width="800">
                    <Card
                        @cancel="item.dialog = false"
                        @accept="updateCostumer(item.id, item)"
                    >
                        <template #updateContent>
                            <v-container fluid>
                                <v-row class="px-4">
                                    <v-col cols="12" md="3" sm="6">
                                        <TextField
                                            v-model.number="item.cuit"
                                            label="Cuit"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="3" sm="6">
                                        <TextField
                                            v-model="item.nombre_local"
                                            label="Nombre del local"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="3" sm="6">
                                        <TextField
                                            v-model="item.nombre"
                                            label="Nombre"
                                    /></v-col>
                                    <v-col cols="12" md="3" sm="6"
                                        ><TextField
                                            v-model="item.apellido"
                                            label="Apellido"
                                    /></v-col>
                                    <v-col cols="12" md="3" sm="6">
                                        <Select
                                            v-model="updateStoreProvince"
                                            :placeholder="
                                                item.localidad.provincia
                                                    .provincia
                                            "
                                            :items="updateStoreProvinces"
                                            item-text="provincia"
                                    /></v-col>
                                    <v-col cols="12" md="3" sm="6">
                                        <Select
                                            v-model="updateStoreLocation"
                                            :items="updateStoreLocations"
                                            :placeholder="
                                                item.localidad.localidad
                                            "
                                            item-text="localidad"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6" sm="6"
                                        ><TextField
                                            v-model="item.direccion"
                                            label="Direccion del local"
                                    /></v-col>
                                    <v-col cols="12" md="3" sm="6">
                                        <Select
                                            v-model="updateProvince"
                                            :placeholder="
                                                item.localidad_entrega.provincia
                                                    .provincia
                                            "
                                            :items="updateProvinces"
                                            item-text="provincia"
                                    /></v-col>
                                    <v-col cols="12" md="3" sm="6">
                                        <Select
                                            v-model="updateLocation"
                                            :items="updateLocations"
                                            :placeholder="
                                                item.localidad_entrega.localidad
                                            "
                                            item-text="localidad"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6" sm="6"
                                        ><TextField
                                            v-model="item.direccion_entrega"
                                            label="Direccion de envio"
                                    /></v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </Card>
                </Dialog>
                <!-- <Icon
                    small
                    icon="mdi-delete"
                    @click="item.deleteDialog = true"
                /> -->
                <!-- <Dialog v-model="item.deleteDialog" width="500">
                    <Card
                        :grid="false"
                        acceptText="Eliminar"
                        title="Seguro que desea eliminar este cliente mayorista?"
                        @cancel="item.deleteDialog = false"
                    />
                </Dialog> -->
            </template>
        </DataTable>
    </div>
</template>

<script>
import DataTable from "@/components/vuetify/DataTable";
import Button from "@/components/vuetify/Button";
import Icon from "@/components/vuetify/Icon.vue";
import Dialog from "@/components/vuetify/Dialog.vue";
import TextField from "@/components/vuetify/TextField.vue";
import Select from "@/components/vuetify/Select.vue";
import Card from "@/components/vuetify/Card.vue";
import Snackbar from "@/components/vuetify/Snackbar.vue";
import { getProvinces } from "@/services/provincias.js";
import { provideNewWholesaleCustomer } from "@/services/conection.js";
import {
    getWholesaleCostumer,
    createWholesaleCostumer,
    updateWholesaleCostumer,
    resendEmail,
} from "@/services/wholesale.js";
import { transformDate } from "@/helpers/dateHelper";
import { mapState, mapActions } from "vuex";

export default {
    components: {
        DataTable,
        Button,
        Icon,
        Dialog,
        TextField,
        Card,
        Select,
        Snackbar,
    },
    data() {
        return {
            items: [
                {
                    title: "Editar",
                },
                {
                    title: "Reenviar mail",
                },
            ],
            transformDate,
            snackbars: [
                {
                    display: false,
                    text: "",
                    color: "",
                },
                {
                    display: false,
                    text: "",
                    color: "",
                },
            ],
            checkbox: false,
            createDialog: { showDialog: false },
            deleteDialog: { showDialog: false },
            provinces: [],
            storeProvinces: [],
            updateProvinces: [],
            updateLocations: [],
            updateProvince: "",
            updateLocation: "",
            updateProvinceId: null,
            updateLocationId: null,
            updateStoreProvinces: [],
            updateStoreLocations: [],
            updateStoreProvince: "",
            updateStoreLocation: "",
            updateStoreProvinceId: null,
            updateStoreLocationId: null,
            locations: [],
            storeLocations: [],
            customers: [],
            dialog: false,
            storeProvince: "",
            storeLocation: "",
            province: "",
            provinceId: null,
            storeProvinceId: null,
            location: "",
            locationId: null,
            storeLocationId: null,
            address: "",
            headers: [
                {
                    text: "Fecha de creacion",
                    value: "created_at",
                },
                {
                    text: "Cuit",
                    value: "cuit",
                },
                {
                    text: "Nombre del local",
                    value: "nombre_local",
                },
                {
                    text: "Nombre Completo",
                    value: "nombre",
                },

                {
                    text: "Direccion del local",
                    value: "direccion",
                },
                {
                    text: "Direccion de envio",
                    value: "envio",
                },
                {
                    text: "",
                    value: "id",
                },
            ],
        };
    },
    computed: {
        fields() {
            return [
                {
                    model: "",
                    label: "Cuit",
                    placeholder: "",
                    type: "number",
                    md: "2",
                },
                {
                    model: "",
                    label: "Email",
                    placeholder: "",
                    type: "email",
                },
                {
                    model: "",
                    label: "Local",
                    placeholder: "",
                    type: "text",
                    md: "2",
                },
                {
                    model: "",
                    label: "Nombre",
                    placeholder: "",
                    type: "text",
                    md: "2",
                },
                {
                    model: "",
                    label: "Apellido",
                    placeholder: "",
                    type: "text",
                },
                {
                    isProvince: true,
                },
                {
                    isLocation: true,
                },
                {
                    model: "",
                    label: "Direccion",
                    placeholder: "",
                    type: "text",
                    cols: "12",
                    md: "6",
                },
            ];
        },
        ...mapState(["userToken"]),
    },
    methods: {
        ...mapActions(["handleLoading"]),
        cancelDialog(dialog) {
            dialog.showDialog = false;
        },
        async resendEmail(item) {
            try {
                this.handleLoading(true);
                const response = await resendEmail(item.id, this.userToken);
                if (response.status === 200) {
                    this.snackbars[0].display = true;
                    this.snackbars[0].text =
                        "El mail ha sido reenviado con exito";
                    this.snackbars[0].color = "green";
                }
            } catch (error) {
                console.log(error);
                this.snackbars[0].display = true;
                this.snackbars[0].color = "red";
                this.snackbars[0].text = "Error al reenviar el mail";
            } finally {
                this.handleLoading(false);
            }
        },
        async postCostumer() {
            const charset =
                "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-";
            let password = "";
            const length = 10;
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * charset.length);
                password += charset[randomIndex];
            }
            try {
                this.handleLoading(true);
                const transformedData = {
                    nombre: this.fields[3].model,
                    apellido: this.fields[4].model,
                    nombre_local: this.fields[2].model,
                    cuit: this.fields[0].model,
                    email: this.fields[1].model,
                    direccion: this.fields[7].model,
                    id_localidad: this.storeLocationId,
                    password: password,
                    direccion_entrega:
                        this.address === ""
                            ? this.fields[7].model
                            : this.address,
                    id_localidad_entrega:
                        this.locationId === null
                            ? this.storeLocationId
                            : this.locationId,
                    localidad: {
                        id: this.storeLocationId,
                        id_provincia: this.storeProvinceId,
                        localidad: this.storeLocation,
                        provincia: {
                            id: this.storeProvinceId,
                            provincia: this.storeProvince,
                        },
                    },
                    localidad_entrega: {
                        id:
                            this.locationId === null
                                ? this.storeLocationId
                                : this.locationId,
                        id_provincia:
                            this.provinceId === null
                                ? this.storeProvinceId
                                : this.provinceId,
                        localidad:
                            this.location === ""
                                ? this.storeLocation
                                : this.location,
                        provincia: {
                            id:
                                this.provinceId === null
                                    ? this.storeProvinceId
                                    : this.provinceId,
                            provincia:
                                this.province === ""
                                    ? this.storeProvince
                                    : this.province,
                        },
                    },
                };
                const response = await createWholesaleCostumer(
                    transformedData,
                    this.userToken
                );
                if (response.status === 200 || response.status === 201) {
                    this.getWholesaleCostumers();
                    this.cancelDialog(this.createDialog);
                    this.snackbars[0].display = true;
                    this.snackbars[0].text =
                        "Cuenta mayorista creada con exito";
                    this.snackbars[0].color = "green";

                    try {
                        const costumerProvided = {
                            username: this.fields[0].model.toString(),
                            email: this.fields[1].model,
                            password: password,
                            first_name: this.fields[3].model,
                            last_name: this.fields[4].model,
                            roles: ["customer"],
                            meta: {
                                b2bking_b2buser: "yes",
                                b2bking_customergroup: "51283",
                            },
                            acf: {
                                cuit: this.fields[0].model,
                            },
                        };
                        const provideCostumer =
                            await provideNewWholesaleCustomer(costumerProvided);
                        if (
                            provideCostumer.status === 200 ||
                            provideCostumer.status === 201
                        ) {
                            this.handleLoading(false);
                            this.snackbars[1].display = true;
                            this.snackbars[1].text =
                                "La cuenta ya esta disponible en la web";
                            this.snackbars[1].color = "green";
                        } else {
                            this.snackbars[1].display = true;
                            this.snackbars[1].text =
                                "La cuenta no pudo ser enviada a la web";
                            this.snackbars[1].color = "red";
                        }
                        this.address = "";
                        this.fields[2].model = "";
                        this.fields[3].model = "";
                        this.fields[4].model = "";
                        this.fields[1].model = "";
                        this.fields[0].model = "";
                        this.fields[6].model = "";
                        this.storeLocationId = null;
                        this.location = "";
                        this.province = "";
                        this.locationId = null;
                        this.provinceId = null;
                        this.storeProvinceId = null;
                        this.storeLocation = null;
                        this.storeProvince = null;
                    } catch (error) {
                        console.log(error);
                        if (error === undefined) {
                            this.snackbars[1].display = true;
                            this.snackbars[1].color = "red";
                            this.snackbars[1].text =
                                "La cuenta no pudo ser enviada a la web";
                            this.handleLoading(false);
                        }
                    }
                } else {
                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "red";
                    this.snackbars[0].text =
                        "Error al crear la cuenta mayorista";
                }
            } catch (error) {
                console.log(error);
            }
        },
        async updateCostumer(id, params) {
            try {
                this.handleLoading(true);
                const updatedCustomer = {
                    nombre: params.nombre,
                    apellido: params.apellido,
                    nombre_local: params.nombre_local,
                    cuit: params.cuit,
                    direccion: params.direccion,
                    id_localidad:
                        this.updateStoreLocationId === null
                            ? params.id_localidad
                            : this.updateStoreLocationId,
                    direccion_entrega: params.direccion_entrega,
                    id_localidad_entrega:
                        this.updateLocationId === null
                            ? params.id_localidad_entrega
                            : this.updateLocationId,
                    localidad: {
                        id:
                            this.updateStoreLocationId === null
                                ? params.id_localidad
                                : this.updateStoreLocationId,
                        id_provincia:
                            this.updateStoreProvinceId === null
                                ? params.localidad.provincia.id
                                : this.updateStoreProvinceId,
                        localidad:
                            this.updateStoreLocation === ""
                                ? params.localidad.localidad
                                : this.updateStoreLocation,
                        provincia: {
                            id:
                                this.updateStoreProvinceId === null
                                    ? params.localidad.provincia.id
                                    : this.updateStoreProvinceId,
                            provincia:
                                this.updateStoreProvince === ""
                                    ? params.localidad.provincia.provincia
                                    : this.updateStoreProvince,
                        },
                    },
                    localidad_entrega: {
                        id:
                            this.updateLocationId === null
                                ? params.id_localidad_entrega
                                : this.updateLocationId,
                        id_provincia:
                            this.updateProvinceId === null
                                ? params.localidad_entrega.provincia.id
                                : this.updateProvinceId,
                        localidad:
                            this.updateLocation === ""
                                ? params.localidad_entrega.localidad
                                : this.updateLocation,
                        provincia: {
                            id:
                                this.updateProvinceId === null
                                    ? params.localidad_entrega.provincia.id
                                    : this.updateProvinceId,
                            provincia:
                                this.updateProvince === ""
                                    ? params.localidad_entrega.provincia
                                          .provincia
                                    : this.updateProvince,
                        },
                    },
                };
                const response = await updateWholesaleCostumer(
                    id,
                    updatedCustomer,
                    this.userToken
                );
                if (response.status === 200 || response.status === 201) {
                    this.handleLoading(false);
                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "green";
                    this.snackbars[0].text =
                        "La cuenta mayorista ha sido modificada con exito";
                    params.dialog = false;
                }
            } catch (error) {
                if (error) {
                    this.handleLoading(false);
                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "red";
                    this.snackbars[0].text =
                        "La cuenta mayorista no ha podido ser modificada";
                }
                console.log(error);
            }
        },
        async getWholesaleCostumers() {
            try {
                this.handleLoading(true);
                const response = await getWholesaleCostumer(this.userToken);
                if (response.status === 200) {
                    this.handleLoading(false);
                    this.customers = response.data.map((customer) => ({
                        ...customer,
                        dialog: false,
                        deleteDialog: false,
                    }));
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    async created() {
        this.handleLoading(true);
        try {
            const response = await getProvinces();
            this.provinces = response;
            this.storeProvinces = response;
            this.updateProvinces = response;
            this.updateStoreProvinces = response;
        } catch (error) {
            console.log(error);
        }
        this.getWholesaleCostumers();
    },
    watch: {
        province(newValue) {
            if (newValue) {
                const selectedProvince = this.provinces.find(
                    (province) => province.provincia === newValue
                );

                if (selectedProvince) {
                    this.locations = selectedProvince.localities;
                    this.provinceId = selectedProvince.id;
                }
            }
        },
        storeProvince(newValue) {
            if (newValue) {
                const selectedProvince = this.storeProvinces.find(
                    (province) => province.provincia === newValue
                );

                if (selectedProvince) {
                    this.storeLocations = selectedProvince.localities;
                    this.storeProvinceId = selectedProvince.id;
                }
            }
        },
        storeLocation(newValue) {
            if (newValue) {
                const selectedLocation = this.storeLocations.find(
                    (location) => location.localidad === newValue
                );

                if (selectedLocation) {
                    this.storeLocation = selectedLocation.localidad;
                    this.storeLocationId = selectedLocation.id;
                }
            }
        },
        updateProvince(newValue) {
            if (newValue) {
                const selectedProvince = this.updateProvinces.find(
                    (province) => province.provincia === newValue
                );

                if (selectedProvince) {
                    this.updateLocations = selectedProvince.localities;
                    this.updateProvinceId = selectedProvince.id;
                }
            }
        },
        updateLocation(newValue) {
            if (newValue) {
                const selectedLocation = this.updateLocations.find(
                    (location) => location.localidad === newValue
                );

                if (selectedLocation) {
                    this.updateLocation = selectedLocation.localidad;
                    this.updateLocationId = selectedLocation.id;
                }
            }
        },
        updateStoreProvince(newValue) {
            if (newValue) {
                const selectedProvince = this.updateStoreProvinces.find(
                    (province) => province.provincia === newValue
                );

                if (selectedProvince) {
                    this.updateStoreLocations = selectedProvince.localities;
                    this.updateStoreProvinceId = selectedProvince.id;
                }
            }
        },
        updateStoreLocation(newValue) {
            if (newValue) {
                const selectedLocation = this.updateStoreLocations.find(
                    (location) => location.localidad === newValue
                );

                if (selectedLocation) {
                    this.updateStoreLocation = selectedLocation.localidad;
                    this.updateStoreLocationId = selectedLocation.id;
                }
            }
        },
        location(newValue) {
            if (newValue) {
                const selectedLocation = this.locations.find(
                    (location) => location.localidad === newValue
                );

                if (selectedLocation) {
                    this.location = selectedLocation.localidad;
                    this.locationId = selectedLocation.id;
                }
            }
        },
    },
};
</script>
