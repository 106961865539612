import axios from './instance';

export const provideNewWholesaleCustomer = async(params) => {
    try {
        const res = await axios.post(`${process.env.VUE_APP_API_URL_WORDPRESS_CONNECTION_PROD}/wp-json/wp/v2/users`, params, {
            auth: {
                username: process.env.VUE_APP_WORDPRESS_CONNECTION_AUTH_USERNAME,
                password: process.env.VUE_APP_WORDPRESS_CONNECTION_AUTH_PASSWORD
            }
        });
        return res;
    } catch (err) {
        console.log(err.response)

        throw err.response;
    }
};