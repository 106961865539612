import axios from './instance'

const configToken = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}

export const resendEmail = async(wholesaleClientId, token) => {
    try {
        const res = await axios.get(`/wholesale-client-send-mail?id=${wholesaleClientId}`, configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}

export const getWholesaleCostumer = async(token) => {
    try {
        const res = await axios.get('/wholesale-clients', configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}

export const createWholesaleCostumer = async(params, token) => {
    try {
        const res = await axios.post('/wholesale-clients', params, configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}
export const updateWholesaleCostumer = async(id, params, token) => {
    try {
        const res = await axios.put(`/wholesale-clients/${id}`, params, configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}

export const getProducts = async(token, q) => {
    try {
        let url = "/products"
        if (q !== undefined && q !== '') { url += `?q=${q}` }
        const res = await axios.get(url, configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}

export const createProducts = async(params, token) => {
    try {
        const res = await axios.post('/products', params, configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}
export const updateProducts = async(id, params, token) => {
    try {
        const res = await axios.put(`/products/${id}`, params, configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}

export const putProductInWordpress = async(id, params) => {
    try {
        const res = await axios.put(`${process.env.VUE_APP_WORDPRESS_CONNECTION_URL}${id}`, params, {
            auth: {
                username: process.env.VUE_APP_WORDPRESS_AUTH_USERNAME,
                password: process.env.VUE_APP_WORDPRESS_AUTH_PASSWORD
            }
        })
        return res
    } catch (err) {
        console.log(err)

        throw err.response
    }
}